// @flow
import SkuSelect from 'components/SkuSelect';
import {Hr} from 'componentsStyled/Typography/Other';
import {Text} from 'componentsStyled/Typography/Texts';
import {listAccessories} from 'data/accessories/graphql/queries';
import {selectAppConfig} from 'data/app/selectors';
import type {Booking} from 'data/bookings/types';
import {minHeightImg, selectIndexImage} from 'data/images/helpers';
import {momentFromDateString} from 'data/units/date/helpers';
import type {Customer} from 'data/user/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import {isEmpty, path} from 'ramda';
import React from 'react';
import {type HOC, branch, compose, withProps} from 'recompose';

import type {ReservationDetails} from '../../../../data/reservations/types';
import {AccessoriesWrap, Body, ImageWrap, Left, ProductRow, Right, StyledImage} from './styled';

type Props = {
  booking: Booking,
};

const getCustomer = (c: Customer) =>
  path(['user'], c)
    ? `${c.user.profiles[0].firstName} ${c.user.profiles[0].lastName}`
    : 'No customer data available';

const BookingItemsDetail = ({booking, appConfig, accessories}) => {
  const {reservations, order} = booking;
  const {user} = order.customer;
  const {email} = user;
  const startDate = momentFromDateString(booking.start);
  const endDate = momentFromDateString(booking.end);
  const bookingExternalId = booking.externalId;
  // All reservations in a booking will share the same comment and status
  const comment = (reservations[0].details || (({}: any): ReservationDetails)).comment;

  return (
    <Body>
      <h3>{bookingExternalId}</h3>
      <Text medium>
        {startDate.format('MMMM D')} - {endDate.format('MMMM D')}
      </Text>
      <Text>{getCustomer(order.customer)}</Text>
      <Text>{email}</Text>
      <Hr />
      <Text bold uppercase>
        products
      </Text>
      {reservations.map((reservation, index) => {
        // Deeply nested object may not be available on components first render
        if (
          !reservation.productItem.productVariant ||
          !reservation.productItem.productVariant.product
        ) {
          return null;
        }
        const productVariant = reservation.productItem.productVariant;
        const indexImage = selectIndexImage(productVariant.product.images);
        const imageSize = minHeightImg(indexImage, 60);

        return (
          <ProductRow key={index}>
            <Left>
              <div>
                <ImageWrap key={imageSize.url}>
                  <StyledImage src={imageSize.url} />
                </ImageWrap>
              </div>
              <div>
                <Text>{productVariant.product.name}</Text>
                <Text>{productVariant.name}</Text>
                <Text>{productVariant.shelfLocation}</Text>
              </div>
            </Left>
            <Right>
              {(reservation.status === 'confirmed' || reservation.status === 'pre_processing') && (
                <SkuSelect data-cy="sku-select" reservation={reservation} />
              )}
            </Right>
          </ProductRow>
        );
      })}
      <Hr />
      {accessories && (
        <React.Fragment>
          <Text bold uppercase>
            accessories
          </Text>
          {accessories.map((accessory, i) => (
            <AccessoriesWrap>
              <img key={i} src={accessory.url} alt={accessory.name} title={accessory.name} />
              <Text>{accessory.name}</Text>
            </AccessoriesWrap>
          ))}
        </React.Fragment>
      )}
      <Hr />
      {comment && (
        <React.Fragment>
          <Text bold uppercase>
            comment
          </Text>
          <Text>{comment}</Text>
        </React.Fragment>
      )}
    </Body>
  );
};

const mapStateToProps = state => ({
  appConfig: selectAppConfig(state),
});

const enhancer: HOC<*, Props> = compose(
  withConnect(mapStateToProps),
  // If accessories exist in the reservation, the following logic
  // is run to prepare rendering of information to the shop employee
  branch(
    props =>
      props.booking.reservations.some(
        reservation => !isEmpty(reservation.pricingDetail.accessories)
      ),
    compose(
      withQuery(listAccessories),
      withProps(props => {
        const reservationAccessories = props.booking.reservations.map(
          reservation => reservation.pricingDetail.accessories
        );
        // flatten reservationAccessories
        const accessoriesList = reservationAccessories.reduce((a, b) => a.concat(b), []);
        const displayReservationAccessories = accessoriesList.map(reservationAccessory => {
          const accessory = props.data.find(a => a.name === reservationAccessory.name);
          if (accessory) {
            return accessory;
          }
          // case when accessory has been deleted from the shop
          return {name: reservationAccessory.name, url: ''};
        });
        return {accessories: displayReservationAccessories};
      })
    )
  )
);

export default enhancer(BookingItemsDetail);
