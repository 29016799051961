// @flow
import Loader from 'components/Loader';
import QuiverList from 'components/QuiverList';
import {categoryId as allIds} from 'data/app/constants';
import {selectActivity} from 'data/app/selectors';
import {searchInInventoryQuery} from 'data/product/graphql/queries';
import type {ProductItem} from 'data/product/types';
import withConnect from 'hoc/withConnect';
import withQuery from 'hoc/withQuery';
import {useIntersectionObserver} from 'hooks/useIntersectionObserver';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

type Props = {
  data: ProductItem[],
  loadingMore: boolean,
  loadMore: Function,
};

//Sorting applied to products within an affiliate
const sorting = {
  ALPHABETICAL: 'alphabetical',
  //Sort with priority to damaged & in repair products
  PRIORITY: 'priority',
};

const QuiverData = ({data, search, loadingMore, loadMore}: Props) => {
  const observerRef = useIntersectionObserver(loadMore, {
    threshold: 0.5,
    rootMargin: '1000px',
  }); // to give enough time to load more items smoothly before being visible.
  return (
    <div>
      <QuiverList products={data} />
      <Loader hide={!loadingMore} />
      <div ref={observerRef}></div>
    </div>
  );
};

const mapStateToProps = state => ({
  category: selectActivity(state),
});

type Outter = {|
  search: ?string,
|};

const enhancer: HOC<*, Outter> = compose(
  withConnect(mapStateToProps),
  withQuery(searchInInventoryQuery, {
    variables: ({category, search}) => ({
      categoryIds: category && category.categoryId !== allIds.ALL ? category.categoryId : [],
      query: search === '' ? undefined : search,
      sorting: sorting.PRIORITY,
    }),
  })
);

export default enhancer(QuiverData);
