// @flow
import {selectActivity} from 'data/app/selectors';
import withConnect from 'hoc/withConnect';
import * as React from 'react';
import {type HOC, compose} from 'recompose';

import Input from './Input';
import {SearchWrap} from './styled';

const Search = ({value, onChange}) => {
  return (
    <SearchWrap>
      <Input onChange={onChange} value={value} />
    </SearchWrap>
  );
};

const mapStateToProps = state => ({
  category: selectActivity(state),
});

type Outter = {|
  onChange: (?string) => mixed,
  value: ?string,
|};

const enhancer: HOC<*, Outter> = compose(withConnect(mapStateToProps));

export default enhancer(Search);
